import React from 'react';
import { graphql } from 'gatsby';
import { Main, HtmlBody, Layout } from '../components';

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query PageByPath($slug: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;

const PageTemplate = props => {
  const {
    data: {
      page: { html }
    }
  } = props;

  return (
    <Layout>
      <Main>{() => <HtmlBody html={html} />}</Main>
    </Layout>
  );
};

export default PageTemplate;
